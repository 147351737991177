const apiConfigs = {
  "regional": {
    "apiHost": "https://api.mit.edu",
    "paths": {
      "apiPath": "frc-v1",
      "digitalId": "digital-id-v1",
      "finance": "finance-v3"
    },
    "coupaUrl": "https://mit.coupahost.com"
  },
  "global": {
    "apiHost": "https://global.api.mit.edu",
    "paths": {
      "apiPath": "frc/v2",
      "digitalId": "digital-id/v1",
      "finance": "finance/v3"
    },
    "coupaUrl": "https://mit.coupahost.com"
  }
};
export default apiConfigs;