import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'
import { clearCostObjectReport, runCostObjectReport, saveFinancialPeriod, saveSelectedProfitCenters, saveSelectedSupervisors } from 'redux/thunk/costObjectReport'
import { ItemResultType } from 'redux/thunk/types'

// Define a type for the slice state
export interface CostObjectReportState {
  results: ItemResultType[]
  fiscalPeriod: string
  error: any
  formData: any
  selectedProfitCenters: any[]
  selectedSupervisors: any[]
}

// Define the initial state using that type
const initialState: CostObjectReportState = {
  results: [],
  fiscalPeriod: '',
  error: undefined,
  formData: [],
  selectedProfitCenters: [],
  selectedSupervisors: []
}

export const costObjectReportSlice = createSlice({
  name: 'costObjectReportResults',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(saveSelectedProfitCenters.fulfilled, (state, { payload }) => {
      state.selectedProfitCenters = payload
    })
    builder.addCase(saveSelectedSupervisors.fulfilled, (state, { payload }) => {
      state.selectedSupervisors = payload
    })
    builder.addCase(saveFinancialPeriod.fulfilled, (state, { payload }) => {
      state.fiscalPeriod = payload
    })
    builder.addCase(runCostObjectReport.fulfilled, (state, { payload }) => {
      state.results = payload.results
      state.fiscalPeriod = payload.fiscalPeriod
      state.error = payload.error
      state.formData = payload.formData
    })
    builder.addCase(runCostObjectReport.rejected, (state, action) => {})
    builder.addCase(clearCostObjectReport.fulfilled, (state, { payload }) => {
      state.results = []
    })
    builder.addCase(clearCostObjectReport.rejected, (state, action) => {})
  }
})

// Other code such as selectors can use the imported `RootState` type
export const selectCostObjectReportResults = (state: RootState): any => state.costObjectReport.results

export default costObjectReportSlice.reducer
