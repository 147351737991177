import { DigitalIdApi } from './DigitalIdApi'
import { FinanceApi } from './FinanceApi'
import { FrcApi } from './FrcApi'

export const apiReducers = {
  [DigitalIdApi.reducerPath]: DigitalIdApi.reducer,
  [FinanceApi.reducerPath]: FinanceApi.reducer,
  [FrcApi.reducerPath]: FrcApi.reducer
}

export const apiMiddleware = [DigitalIdApi.middleware, FinanceApi.middleware, FrcApi.middleware]
