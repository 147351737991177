import { Initialization, Logout } from '@mit/aws-react'
import { HuiThemeProvider } from '@mit/hui-core'
import appConfig from 'app-config-global'
import React, { FC } from 'react'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import { Provider, useSelector } from 'react-redux'
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import { useAppInitializer } from 'redux/hooks/Initializer'
import { RootState, store } from 'redux/store'
import 'scss/main.scss'
import reportWebVitals from './reportWebVitals'

const App = React.lazy(async () => await import('components/app/App').then(module => ({ default: module.App })))

const WrappedApp: FC = () => {
  const { appContact, appDescription, appName } = useSelector((state: RootState) => state.appConfig)
  const { initializeApp } = useAppInitializer()

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path='/logout' element={<Logout />} />
        <Route
          path='*'
          element={
            <Initialization
              App={App}
              appInitializer={initializeApp}
              appConfigData={appConfig}
              loaderProps={{
                contactEmail: appContact,
                name: appName,
                type: 'Default',
                isLoading: true
              }}
            />
          }
        />
      </>
    )
  )

  return (
    <>
      <Helmet titleTemplate={`${appName} - %s`} defaultTitle={appName}>
        {/* <title>{appName}</title> */}
        <meta name='description' content={appDescription} />
      </Helmet>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </>
  )
}

ReactDOM.render(
  <Provider store={store}>
    <HuiThemeProvider variant='default'>
      <WrappedApp />
    </HuiThemeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()