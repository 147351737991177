import { configureStore } from '@reduxjs/toolkit'
import { apiMiddleware, apiReducers } from 'redux/api'
import AppConfigReducer from '../slice/AppConfigSlice'
import CostObjectReportReducer from '../slice/CostObjectReportSlice'
import TransactionTypeReportReducer from '../slice/TransactionTypeReportSlice'

import logger from 'redux-logger'
import { TypedUseSelectorHook, useSelector } from 'react-redux'

export const store = configureStore({
  reducer: {
    appConfig: AppConfigReducer,
    costObjectReport: CostObjectReportReducer,
    transactionTypeReport: TransactionTypeReportReducer,
    ...apiReducers
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(apiMiddleware)
      .concat(logger as any)
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
