import { AuthProvider } from '@mit/aws-react'
import apiConfigs from 'Api/api-config'
import appConfig from 'app-config-global'

export const prepareHeaders = async (headers: Headers): Promise<Headers> => {
  const authorizationToken = await new AuthProvider(appConfig).getToken()
  headers.append('Authorization', `Bearer ${authorizationToken}`)
  return headers
}

export const getConfigKey = (): keyof typeof apiConfigs => {
  const isGlobalEnabled = localStorage.getItem('isGlobalEnabled') === 'true' ?? false;
  return (isGlobalEnabled ? 'global': 'regional') as keyof typeof apiConfigs;
}
