import { createAsyncThunk } from '@reduxjs/toolkit'
import { ItemResultType, Review } from 'redux/thunk/types'
import { getConfigKey, prepareHeaders } from 'utility/Api'
import apiConfigs from 'Api/api-config'

export const saveReportReviews = createAsyncThunk(
  'costObjectReport/saveReportReviews',
  // Declare the type your function argument here:
  async (formData: any): Promise<any> => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const baseUrl = apiConfigs[getConfigKey()].apiHost
    const apiPath = apiConfigs[getConfigKey()].paths.apiPath
    const filteredReviews = formData.reportConfig
    const response = await fetch(`${baseUrl}/${apiPath}/reviews/save`, {
      method: 'POST',
      headers: await prepareHeaders(headers),
      body: JSON.stringify({reportConfig: filteredReviews})
    })
    const responseData = await response.json()

    return responseData
  }
)
export const runReport = async (page: number = 1, formData: any, sapResponseData: any): Promise<any> => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  const baseUrl = apiConfigs[getConfigKey()].apiHost
  const apiPath = apiConfigs[getConfigKey()].paths.apiPath
  const prepareData = (formData: any): any => {
    const supervisors = formData.supervisors.map((supervisor: any) => {
      return supervisor.id
    })
    const profitCenters = formData.profitCenters.map((profitCenter: any) => {
      return profitCenter.id
    })
    const costObjects = formData.costObjects.map((costObject: any) => {
      return costObject.id
    })
    return {
      fiscalPeriod: formData.fiscalPeriod,
      supervisors,
      profitCenters,
      costObjects
    }
  }
  const requestHeaders = await prepareHeaders(headers)
  const responseLoop = await fetch(`${baseUrl}/${apiPath}/costObjectReport?page=${page}&pageMax=25000`, {
    method: 'POST',
    headers: requestHeaders,
    body: JSON.stringify({
      reportConfig: prepareData(formData)
    })
  })

  const sapResponseDataLoop = await responseLoop.json()
  let nextPage = parseInt(sapResponseData.nextPage)

  nextPage = parseInt(sapResponseDataLoop.nextPage)
  sapResponseData.row = sapResponseData.row.concat(sapResponseDataLoop.row)
  if (!isNaN(nextPage) && sapResponseDataLoop.row.length > 0 && sapResponseDataLoop.row[0].TYPE !== 'E') {
    if (nextPage !== 0) {
      await runReport(nextPage, formData, sapResponseData)
    }
  }
  return sapResponseData
}
export const runCostObjectReport = createAsyncThunk(
  'costObjectReport/runCostObjectReport',
  // Declare the type your function argument here:
  async (formData: any): Promise<any> => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const baseUrl = apiConfigs[getConfigKey()].apiHost
    const apiPath = apiConfigs[getConfigKey()].paths.apiPath
    const sapResponseData = await runReport(1, formData, { row: [] }).catch(e => {
      return 'Your search returned too many records. Please refine your search and try again.'
    })
    const requestHeaders = await prepareHeaders(headers)

    let error: any

    if (sapResponseData.row[0].MESSAGE !== null && sapResponseData.row[0].MESSAGE !== undefined) {
      error = sapResponseData.row[0].MESSAGE
      return {
        fiscalPeriod: formData.fiscalPeriod,
        results: [],
        error,
        formData
      }
    }

    const finalData: ItemResultType[] = sapResponseData.row.map((data: any) => {
      let coupaPoNumber = data.PO_COUP.split('COUPA')[1]
      if (coupaPoNumber === undefined) {
        coupaPoNumber = data.PO_COUP
      }
      return {
        id: data.BELNR,
        profitCenter: data.PRCTR,
        costObjectName: `${data.COST_OBJECT as string} - ${data.OBJNAME as string}`,
        transactionType: data.TRANS,
        docTypeDescription: data.DOCTYPE,
        glAccount: data.COST_ELEMENT,
        docDate: data.DOCDATE,
        docNumber: data.DOCNR,
        lineItemText: data.DESCR,
        reference: data.REFNR,
        poNumber: data.PO_DOC,
        transactionDetail: data.DETAIL,
        actualAmount: data.AMOUNT,
        reviewed: Review.NONE,
        risk: data.RISK,
        period: data.PERIO_TEXT,
        coupaPoNumber: coupaPoNumber,
        reason: {
          type: '',
          comment: ''
        }
      }
    })

    /*
        DATA MIGRATION - BY REFBN
    */
    let oldIds = ''
    finalData.forEach((result: ItemResultType) => {
      oldIds += `${result.docNumber.split(',')[0]},`
    })
    const oldReviewResponse = await fetch(`${baseUrl}/${apiPath}/reviews`, {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify({
        docIds: oldIds
      })
    })
    oldIds = oldIds.slice(0, -1)

    const oldDocReviews = await oldReviewResponse.json()
    if (oldDocReviews[0].length > 0) {
      oldDocReviews[0].forEach((result: any) => {
        const findObject = finalData.find((data: ItemResultType) => {
          const localDocNum = `${data.docNumber.split(',')[0]}`
          return localDocNum === result.RE_ID
        })
        if (findObject != null) {
          findObject.reviewed = result.STATUS
          findObject.reason.type = result.REASON_CODE
          findObject.reason.comment = result.CGCOMMENT
        }
      })
    }

    /*
        DATA MIGRATION - BY COUPA REFBN
    */
    let newIds = ''
    finalData.forEach((result: ItemResultType) => {
      newIds += `${result.docNumber.split(',')[1]},`
    })
    const newReviewResponse = await fetch(`${baseUrl}/${apiPath}/reviews`, {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify({
        docIds: newIds
      })
    })
    newIds = newIds.slice(0, -1)

    const newDocReviews = await newReviewResponse.json()
    if (newDocReviews[0].length > 0) {
      newDocReviews[0].forEach((result: any) => {
        const findObject = finalData.find((data: ItemResultType) => {
          const localDocNum = `${data.docNumber.split(',')[1]}`
          return localDocNum === result.RE_ID
        })
        if (findObject != null) {
          findObject.reviewed = result.STATUS
          findObject.reason.type = result.REASON_CODE
          findObject.reason.comment = result.CGCOMMENT
        }
      })
    }
    /*
        REVIEWS BY BELNR
    */
    let ids = ''
    finalData.forEach((result: ItemResultType) => {
      ids += `${result.id},`
    })
    ids = ids.slice(0, -1)
    const reviewResponse = await fetch(`${baseUrl}/${apiPath}/reviews`, {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify({
        docIds: ids
      })
    })
    const docReviews = await reviewResponse.json()
    if (docReviews[0].length > 0) {
      docReviews[0].forEach((result: any) => {
        const findObject = finalData.find((data: ItemResultType) => {
          const localDocNum = `${data.id}`
          return localDocNum === result.RE_ID
        })
        if (findObject != null) {
          findObject.reviewed = result.STATUS
          findObject.reason.type = result.REASON_CODE
          findObject.reason.comment = result.CGCOMMENT
        }
      })
    }

    // Inferred return type: Promise<MyData>
    return {
      fiscalPeriod: formData.fiscalPeriod,
      results: finalData,
      formData
    }
  }
)

export const saveSelectedProfitCenters = createAsyncThunk(
  'costObjectReport/saveSelectedProfitCenters',
  // Declare the type your function argument here:
  async (selected: any[]) => {
    // const response = await fetch(``)
    // // Inferred return type: Promise<MyData>
    // return (await response.json()) as CostObjectReportResult
    return selected
  }
)

export const saveSelectedSupervisors = createAsyncThunk(
  'costObjectReport/saveSelectedSupervisors',
  // Declare the type your function argument here:
  async (selected: any[]) => {
    // const response = await fetch(``)
    // // Inferred return type: Promise<MyData>
    // return (await response.json()) as CostObjectReportResult
    return selected
  }
)

export const clearCostObjectReport = createAsyncThunk(
  'costObjectReport/clearCostObjectReport',
  // Declare the type your function argument here:
  async () => {
    // const response = await fetch(``)
    // // Inferred return type: Promise<MyData>
    // return (await response.json()) as CostObjectReportResult
    return []
  }
)

export const saveFinancialPeriod = createAsyncThunk(
  'costObjectReport/saveFinancialPeriod',
  // Declare the type your function argument here:
  async (period: string) => {
    // const response = await fetch(``)
    // // Inferred return type: Promise<MyData>
    // return (await response.json()) as CostObjectReportResult
    return period
  }
)
