import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'
import { clearTransactionTypeReport, runTransactionTypeReport } from 'redux/thunk/transactionTypeReport'
import { ItemResultType } from 'redux/thunk/types'

// Define a type for the slice state
export interface TransactionTypeReportState {
  results: ItemResultType[]
  fiscalPeriod: string
  error: any
  formData: any
}

// Define the initial state using that type
const initialState: TransactionTypeReportState = {
  results: [],
  fiscalPeriod: '',
  error: undefined,
  formData: []
}

export const transactionTypeReportSlice = createSlice({
  name: 'transactionTypeReportResults',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(runTransactionTypeReport.fulfilled, (state, { payload }) => {
      state.results = payload.results
      state.fiscalPeriod = payload.fiscalPeriod
      state.error = payload.error
      state.formData = payload.formData
    })
    builder.addCase(runTransactionTypeReport.rejected, (state, action) => {})
    builder.addCase(clearTransactionTypeReport.fulfilled, (state, { payload }) => {
      state.results = []
    })
    builder.addCase(clearTransactionTypeReport.rejected, (state, action) => {})
  }
})

// Other code such as selectors can use the imported `RootState` type
export const selectTransactionTypeReportResults = (state: RootState): any => state.transactionTypeReport.results

export default transactionTypeReportSlice.reducer
